<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/hamza.jpg"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Ҳамза Ҳакимзода Ниёзий<br />(1889 -1929 )</p></b-card-text
          >
        </b-col>
        <b-col md="6">
           <b-card-text>
          <p class="TextBlack17">
            Ҳамза Ҳакимзода Ниёзий (1889.6.3, Қўқон — 1929. 18.8, Фарғона
            вилояти Шоҳимардон қишлоғи) — шоир, ёзувчи, педагог, театр арбоби.
            Ўзбекистон халқ ёзувчиси (1926). Солкашон маҳалласидаги ески мактаб
            ва мадрасада (1896–1906), кейин қисқа муддат Наманган мадрасасида
            (1908) таҳсил кўрган. 1909-йилда Когонда матбуотда ишлаган. Сўнгра
            Тошкентга келиб, 1910-йили усули жадид мактабларининг ўқув дастури
            билан танишган ва шундай мактабларни дастлаб Қашқардарвоза
            маҳалласида, кейин 1911-йил Қўқон шаҳрининг Ҳожибек гузарида ва
            1914-йил Марғилонда очган. Ҳамза кейинги ҳаёти ва фаолияти давомида
            қисқа муддат Қўқондаги рус-тузем мактабида ўқитувчи, „Шўрои ислом“
            ташкилотининг озуқа шўбасида мирза, „Кенгаш“ ва „Ҳуррият“
            журналларида муҳаррир (1917), Фарғона вилояти маданий-маориф
            бўлимида ходим (1918), Туркистон фронти сиёсий бошқармаси
            ихтиёридаги Ўлка мусулмон сиёсий труппасида режиссёр (1919), „Дор
            ушшафақа“ мактабида мудир (1920), Бухоро вилояти маориф ва ҳарбий
            тарғибот-ташвиқот шўъбаси қошидаги театр труппасида раҳбар (1921),
            Хоразм вилоятидаги касаба союзларининг маданий-маърифий
            муассасаларида мудир (1921-1924), Фарғона маориф қўмитаси
            қарамоғидаги онг-билим шўъбасида мудир ўринбосари (1924) сингари
            турли лавозимларда хизмат қилган. 1926-йилдан еркин ижод билан
            шуғулланган. Aммо раҳбар идораларнинг топшириғи билан 1928-йил
            августдан Шоҳимардонда тарғибот-ташвиқот ишлари олиб борган ва
            сўнгги сафари чоғида ҳалок бўлган. Ҳамзанинг адабий ижоди
            1905–1906-йилларда бошланган. Ҳамза 1908-йил қўшни ўлкаларда нашр
            етилган илғор газеталар ва улардаги тараққийпарварлик ғоялари билан
            танишган. Маърифатли кишилар таъсирида „миллий шеърлар“ ёза
            бошлаган. Ҳамза шу йили „ески подшолар турмушидан“ олинган „Ҳақиқат
            кимда?“ деган „роман“ ҳам ёзган (бу асар ўша вақтдаёқ йўқолган).
            Ҳамза 1905-йилдан мумтоз шоирларга ергашган ҳолда ғазаллар ёза
            бошлаган. 1914-йилда тартиб берилган қўлёзма девонига шоирнинг
            1905–1914-йилларда „Ниҳоний“ тахаллуси билан ёзган 214 та шеъри
            киритилган. Шоирнинг „Гул“ (1913–1916), „Миллий ашулалар учун миллий
            шеърлар мажмуаси“ (1915–1917) шеърий тўпламларидаги „Йиғла
            Туркистон“, „Яхши ҳолин йўқотган оқибациз Туркистон“, „Кўзни очинг,
            қардошлар“, „Дармон истариз“ каби шеърларида миллий уйғониш ғояси
            катта маҳорат билан ифодаланган. 1908–1910-йилларда Ҳамза адабий ва
            ижтимоий фаолиятини жадидчилик ҳаракати билан узвий боғлаган. Ҳамза
            янги усулдаги мактаблар очиш ва бу мактаблар учун янги типдаги ўқув
            қўлланмалари яратиш йўли билан халқни маърифатлаштириш мақсадида
            Қўқон ва Марғилонда очган мактаб ўқувчилари учун „Йенгил адабиёт“,
            „Ўқиш китоби“ (1914) ва „Қироат китоби“ (1915) ўқув қўлланмаларини
            тайёрлаган. Ҳамза Хитой, Ҳиндистон, Aфғонистон, Ерон, Туркия ва Aраб
            мамлакатларида бўлган. Ҳаж амалларини бажарган. 1920-йили Қўқонга
            қайтиб келиб, маориф соҳасида хизмат қилади. Шу йил охирида Бухорога
            боради. Бир йилча яшаб, 1921-йил сўнггида Тошкентга ва ундан
            Хоразмга ўтади ва у ерда профсоюзнинг маданий-маориф шуъъбасида
            ишлайди. Хоразмдан Хўжайлига бориб, 1924-йил июлгача ўзи ташкил
            етган интернатда фаолият кўрсатади. 1925-йили Фарғонага бориб,
            Шоҳимардонда колхоз тузиш, мактаблар очиш, халқнинг саводини чиқариш
            ишлари билан шуғулланади. 1928-йилдан унга нисбатан таъқиб ва тазйиқ
            кўрсатила бошлайди. Моддий ва маънавий қийинчилик, изтиробларни
            бошдан кечиради. 1929-йил 18-мартда Ҳамза тошбўрон қилиниб,
            ваҳшийларча ўлдирилади.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
